


















































































































import gql from 'graphql-tag';
import { v4 as uuid } from 'uuid';
import Component from 'vue-class-component';

import DataApiManager from '@/api/dataApiManager';
import { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import { getEmptyStringProps } from '@/utils/stringUtils';

import { upload } from '../../services/fileUploadService';
import { downloadCsv } from '../data/dataUtils';
import UiButton from '../ui/UiButton.vue';
import UiDatePicker from '../ui/UiDatePicker.vue';
import UiLoading from '../ui/UiLoading.vue';
import UiModal from '../ui/UiModal.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import CategorizeJob from './CategorizeJob.vue';

@Component({
  components: {
    UiCheckbox,
    UiSelect2,
    UiDatePicker,
    UiButton,
    UiLoading,
    UiModal,
    CategorizeJob,
  },
})
export default class RunJob extends BaseVue {
  public actions = [
    { label: 'Delete Transactions', id: 'delete' },
    { label: 'Ignore Transactions', id: 'ignore' },
    { label: 'Mark Transactions as Reconciled', id: 'markReconciled' },
    { label: 'Reconcile Transactions', id: 'reconcile' },
    { label: 'Categorize Transactions', id: 'categorize' },
    { label: 'Uncategorize Transactions', id: 'uncategorize' },
    { label: 'Unignore Transactions', id: 'unignore' },
    { label: 'Unreconcile Transactions', id: 'markUnreconciled' },
    { label: 'CSV Bulk Action', id: 'csvBulkAction' },
    { label: 'Re-price Failed-to-Price Transactions', id: 'repriceFailedToPriceTransactions' },
  ];

  public newDataSourceSchema: { id: string; name: string } | null = null;
  public isCreatingDataSource = false;
  public newDataSourceName = '';

  public async createDataSource() {
    this.isCreatingDataSource = true;
    try {
      const ds = DataApiManager.getInstance();
      const res = await ds.createDataSources(
        this.orgId,
        { name: this.newDataSourceName, schemaId: 'bulk-action-txns', params: null },
        { withCredentials: true }
      );
      if (res.status === 200) {
        this.showSuccessSnackbar('Data Source Created Successfully');
        return res;
      } else {
        this.showErrorSnackbar('Error creating data source');
      }
      return res;
    } catch (e) {
      this.showErrorSnackbar(`Error creating data source: ${e}`);
    } finally {
      this.isCreatingDataSource = false;
    }
  }

  public filesChange(fieldName: string, fileList: any) {
    if (fileList.length === 1) {
      this.uploadData = fileList[0];
    }
  }

  // Someone needs to fill in support for all other transaction types
  // and also implement handlers in the back end
  public transactionTypes = [
    { label: 'All', id: 'all' },
    // { label: 'Trade', id: 'trade' },
    // { label: 'Categorized Internal transfer', id: 'categorizedInternalTransfer' },
  ];

  public allowReconciledModification = false;
  public allowCategorizedModification = false;

  // public wallets = this.$store.state.wallets.wallets;

  public get wallets() {
    const walletsArray = this.$store.state.wallets.wallets;
    if (walletsArray !== undefined && Array.isArray(walletsArray)) {
      if (walletsArray.length === 1) {
        this.selectedWallet = walletsArray[0].id ?? '';
      }
    }

    return walletsArray ? [{ id: 'All', name: 'All Wallets' }, ...walletsArray] : [];
  }

  public selectedAction = 'uncategorize';
  public selectedWallet: string | string[] = '';
  public selectedTransactionType = 'all';
  public startDate = '';
  public endDate = '';
  public dialog = false;
  public loading = false;
  public uploadProgress = 0;
  public uploadStatus = '';
  public isUploading = false;
  public uploadData = null;
  public dataSourceId = ``;

  public async downloadCategorizeTemplate() {
    const columns = ['txnId', 'action'];
    const rows = ['delete', 'markReconciled', 'markUnreconciled', 'uncategorize', 'ignore', 'unignore', 'reconcile'];
    let csvContent = '"' + columns.join('","') + '"\n';
    rows.forEach((row) => {
      csvContent = csvContent + '"","' + row + '"\n';
    });
    downloadCsv(csvContent, 'CSV Bulk Action Template');
  }

  public async doUpload() {
    const res = await this.createDataSource();
    this.isUploading = true;
    this.uploadStatus = 'Uploading';
    this.dataSourceId = res?.data?.id ?? '';
    try {
      const ds = DataApiManager.getInstance();
      const loadDetails = await ds.createDataSourceLoad(this.orgId, this.dataSourceId, { withCredentials: true });
      if (loadDetails.status === 200) {
        // do Upload
        const config = {
          onUploadProgress: (progressEvent: any) => {
            this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 95);
            if (this.uploadProgress === 95) {
              this.uploadStatus = 'Wrapping up.';
            }
          },
        };
        await upload(this.uploadData, loadDetails.data.uploadUrl, config);
        const res = await ds.commitDataSourceLoad(this.orgId, this.dataSourceId, loadDetails.data.id, false, {
          withCredentials: true,
        });
        this.uploadProgress = 100;
        if (res.status === 200) {
          this.showSuccessSnackbar(this.$tc('_dataLoadSuccess'));
        } else {
          this.showErrorSnackbar(
            this.$tc('_dataLoadFailure') + `- ${res.data.message !== '' ? ' - ' + res.data.message : ''}`
          );
          throw new Error(`Commit Data Source load failed ${res.data.message}`);
        }
      } else {
        this.showErrorSnackbar(this.$tc('_dataLoadFailure') + `${loadDetails.data.message}`);
        throw new Error(`Create Data Source load failed ${loadDetails.data.message}`);
      }
    } catch (err) {
      this.showErrorSnackbar(this.$tc('_dataLoadFailure') + `${err}`);
      console.error(`Error creating data source load - ${err}`);
    } finally {
      this.isUploading = false;
      this.uploadProgress = 0;
      this.uploadStatus = '';
    }
  }

  public async runJob() {
    this.loading = true;

    let systemJobId = 'bulk-transaction';
    if (this.selectedAction === 'repriceFailedToPriceTransactions') {
      systemJobId = 'reprice-failed-to-price-transactions';
    }

    let walletIds = Array.isArray(this.selectedWallet) ? this.selectedWallet : [this.selectedWallet];
    walletIds = walletIds.includes('All') ? ['All'] : walletIds;
    const promises = walletIds.map(async (walletId) => {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        systemJobId,
        action: this.selectedAction,
        dataSourceId: this.dataSourceId,
        walletId: walletId,
        startSEC: this.startDate,
        endSEC: this.endDate,
        transactionType: this.selectedTransactionType,
        allowReconciledModification: this.allowReconciledModification,
        allowCategorizedModification: this.allowCategorizedModification,
      };

      return this.$apollo.mutate({
        mutation: gql`
          mutation (
            $orgId: ID!
            $systemJobId: String!
            $action: String!
            $walletId: String
            $startSEC: String
            $endSEC: String
            $dataSourceId: String
            $transactionType: String
            $allowReconciledModification: Boolean
            $allowCategorizedModification: Boolean
          ) {
            runSystemJob(
              orgId: $orgId
              systemJobId: $systemJobId
              action: $action
              walletId: $walletId
              startSEC: $startSEC
              endSEC: $endSEC
              dataSourceId: $dataSourceId
              transactionType: $transactionType
              allowReconciledModification: $allowReconciledModification
              allowCategorizedModification: $allowCategorizedModification
            )
          }
        `,
        variables: vars,
      });
    });

    await Promise.all(promises);
    this.loading = false;

    this.$emit('refresh');
    this.dialog = false;
  }
}
